import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import useWindowSize from '@/hooks/useWindowSize';
import { importVideo } from '@/helper/functions';
import classNames from 'classnames';
import { isTablet } from 'react-device-detect';

import VideoPlayer from '@/components/UI/videoPlayer';
import CustomLink from '@/components/UI/link';

import stars from '@/assets/icons/stars.svg';

import style from './introVideoWithLogo.module.scss';

type Props = {
  text: string;
  buttonText: string;
};

const IntroVideoWithLogo: React.FC<Props> = ({ text, buttonText }) => {
  const { i18n, t } = useTranslation();

  const { windowWidth } = useWindowSize();

  const renderVideoOrImageUk = () =>
    windowWidth > 720 &&
    i18n.language === 'uk' && (
      <div className={style.intro_video}>
        <VideoPlayer
          content={importVideo('start', i18n.language)}
          startPlay={2000}
          otherStyle={style.bigPhone}
        />
      </div>
    );

  const renderVideoOrImageEn = () =>
    windowWidth > 720 &&
    (i18n.language === 'en' || i18n.language === 'ro') && (
      <div className={style.intro_video}>
        <VideoPlayer
          content={importVideo('start', i18n.language)}
          startPlay={2000}
          otherStyle={style.bigPhone}
        />
      </div>
    );

  return (
    <div
      className={classNames(style.intro, {
        [style.isTablet]: isTablet,
      })}
    >
      <div className={style.logo}>
        <span className={style.logo_text}>{t('introVideo.title')}</span>
        <Image src={stars} alt='stars' className={style.logo_image} />
        <span className={style.logo_text}>{i18n.language === 'ro' ? 'ușor pentru' : 'Upgrade'}</span>
      </div>
      <div className={style.upgrate}>
        <span className={style.upgrate_text}>{text}</span>
        <CustomLink
          href='/registration'
          target={false}
          style={style.upgrate_button}
          text={windowWidth > 512 ? buttonText : 'header.add'}
        />
      </div>
      {renderVideoOrImageUk()}
      {renderVideoOrImageEn()}
    </div>
  );
};

export default IntroVideoWithLogo;
